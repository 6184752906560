<template>
    <div>
        <Header  v-if="_newTab"/>
        <personalHeaderList v-else/>
        <!--        <Nav/>-->
        <div class="main">
            <!-- 这是一个面包屑组件，把之前的抽个组件吧 -->
            <div class="my-breadcrumb">
                <MyBreadCrumb
                        :firstPathName="firstPathName"
                        :pathName="currentPathName"
                        ref="MyBreadCrumb"
                        @refresh="refresh"
                />
            </div>

            <div class="firstPage" >
                <div class="home_menu">
                    <div class="home_logo" :style="{marginLeft:_newTab?'2.5rem':'2.6rem'}" @click="$router.push('/home')"></div>
                    <div class="home_search">
                        <div class="home_left" @click="$router.push('/personal/myHomePage')" :class="checkCurrentMenuActive('/personal/myHomePage') && 'active'">
                            <span>首页</span>
                        </div>
                        <div class="home_center" @mouseenter="GRZXShowBool = true" @mouseleave="GRZXShowBool = false">
                            <div class="account">账户中心
                                <div class="triangle"></div>
                            </div>
                            <div class="homeTheDropDownCard" v-if="GRZXShowBool">
                                <dl class="homeGRZX">
                                    <dd  v-for="(item,index) in Information" :key="index"
                                        @click="() => $router.push(`${item.path}`)">
                                        <a>{{item.name }}</a>
                                    </dd>
                                </dl>
                            </div>

                        </div>
                        <div class="home_right" @mouseenter="DZGLShowBool = true" @mouseleave="DZGLShowBool = false">
                            <div class="account">地址管理
                                <div class="triangle"></div>
                            </div>
                            <div class="homeTheDropDownCard" v-if="DZGLShowBool" style="height: 1.03rem">
                                <dl  class="homeGRZX">
                                    <dd  v-for="(item,index) in addressList" :key="index"
                                        @click="() => $router.push(`${item.path}`)">
                                        <a>{{item.name }}</a>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="home_seek">
                        <div class="home_input">
                            <a-input-search
                                    :value="searchVal"
                                    @change="onSearchChange"
                                    @search="onSearch"
                                    placeholder="请输入服务商名、养老机构名或服务名"
                                    enter-button
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="homeCenter">
                <div class="left" v-if="_newTab">
                    <!--          <div class="mine">-->
                    <!--            <p class="homePage" :class="checkCurrentMenuActive('/personal/myHomePage') && 'active'"-->
                    <!--               @click="changePathName('我的首页')">-->
                    <!--              <router-link to="/personal/myHomePage">我的首页</router-link>-->
                    <!--            </p>-->
                    <!--            <div class="mine2"></div>-->
                    <!--          </div>-->
                    <p>
                        我的服务
                    </p>
                    <ul>
                        <li :class="checkCurrentMenuActive('/personal/myService') && 'active'"
                            @click="changePathName('我的服务')">
                            <router-link to="/personal/myService">服务订单</router-link>
                        </li>
                        <li :class="checkCurrentMenuActive('/personal/serviceOrderList') && 'active'"
                            @click="changePathName('我的服务')">
                            <router-link to="/personal/serviceOrderList">服务订购单</router-link>
                        </li>

                        <!--                        <li :class="checkCurrentMenuActive('/personal/thirdPartyOrder') && 'active'"-->
<!--                            @click="changePathName('我的服务')">-->
<!--                            <router-link to="/personal/thirdPartyOrder">第三方服务订单</router-link>-->
<!--                        </li>-->
                        <li :class="checkCurrentMenuActive('/personal/OpenService') && 'active'"
                            @click="changePathName('我的服务')">
                            <router-link to="/personal/OpenService">服务开启列表</router-link>
                        </li>
                        <li :class="checkCurrentMenuActive('/personal/returnservice') && 'active'"
                            @click="changePathName('我的服务')">
                            <router-link to="/personal/returnservice">申请/查询服务退款</router-link>
                        </li>
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/cancelService') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的服务')"-->
<!--                        >-->
<!--                            <router-link to="/personal/cancelService">取消服务订单记录</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/fuwuxiaofei') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的服务')"-->
<!--                        >-->
<!--                            <router-link to="/personal/fuwuxiaofei">服务消费明细</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/serveComment') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的服务')"-->
<!--                        >-->
<!--                            <router-link to="/personal/serveComment">服务评价</router-link>-->
<!--                        </li>-->
                    </ul>
                    <p>
                        我的商品
                    </p>
                    <ul>
                        <li
                                :class="checkCurrentMenuActive('/personal/mygoods') && 'active'"
                                @click="changePathName('我的商品')"
                        >
                            <router-link to="/personal/mygoods">商品订单</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActive('/personal/returngoods') && 'active'
              "
                                @click="changePathName('我的商品')"
                        >
                            <router-link to="/personal/returngoods">申请/查询退换货</router-link>
                        </li>
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/cancelGoods') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的商品')"-->
<!--                        >-->
<!--                            <router-link to="/personal/cancelGoods">取消商品订单记录</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="checkCurrentMenuActive('/personal/payDetail') && 'active'"-->
<!--                                @click="changePathName('我的商品')"-->
<!--                        >-->
<!--                            <router-link to="/personal/payDetail">商品消费明细</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/shopComment') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的商品')"-->
<!--                        >-->
<!--                            <router-link to="/personal/shopComment">商品评价</router-link>-->
<!--                        </li>-->
                    </ul>

                    <p>
                        我的预约
                    </p>
                    <ul>
                        <li :class="
                checkCurrentMenuActive('/personal/myReservation') && 'active'
              "
                                @click="changePathName('我的预约')"
                        >
                            <router-link to="/personal/myReservation">机构预约单</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActive('/personal/reservationList') && 'active'
              "
                                @click="changePathName('我的预约')"
                        >
                            <router-link to="/personal/reservationList">诊疗预约单</router-link>
                        </li>
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/Cancelyuyve') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的预约')"-->
<!--                        >-->
<!--                            <router-link to="/personal/Cancelyuyve">取消预约单记录</router-link>-->
<!--                        </li>-->
<!--                        <li :class="checkCurrentMenuActive('/personal/yuyveComment') && 'active'" @click="changePathName('我的预约')">-->
<!--                            <router-link to="/personal/yuyveComment">预约单评价</router-link>-->
<!--                        </li>-->
                    </ul>
                    <p>我的评价</p>
                    <ul>
                        <li
                                :class="
                checkCurrentMenuActive('/personal/serveComment') && 'active'
              "
                                @click="changePathName('我的服务')"
                        >
                            <router-link to="/personal/serveComment">服务评价</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActive('/personal/shopComment') && 'active'
              "
                                @click="changePathName('我的商品')"
                        >
                            <router-link to="/personal/shopComment">商品评价</router-link>
                        </li>
                        <li :class="checkCurrentMenuActive('/personal/yuyveComment') && 'active'" @click="changePathName('我的预约')">
                            <router-link to="/personal/yuyveComment">养老机构评价</router-link>
                        </li>
                    </ul>



                    <p>
                        我的收藏
                    </p>
                    <ul>
                        <li
                                :class="
                checkCurrentMenuActivetttttt('/personal/myCollection?type=1') &&
                'active'
              "
                                @click="changePathName('收藏的服务')"
                        >
                            <router-link to="/personal/myCollection?type=1">收藏的服务</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActivetttttt('/personal/myCollection?type=5') &&
                'active'
              "
                                @click="changePathName('收藏机构')"
                        >
                            <router-link to="/personal/myCollection?type=5">收藏的机构</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActivetttttt('/personal/myCollection?type=3') &&
                'active'
              "
                                @click="changePathName('收藏的服务商')"
                        >
                            <router-link to="/personal/myCollection?type=3">收藏的服务商</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActivetttttt('/personal/myCollection?type=2') &&
                'active'
              "
                                @click="changePathName('收藏的商品')"
                        >
                            <router-link to="/personal/myCollection?type=2">收藏的商品</router-link>
                        </li>
                        <li
                                :class="
                checkCurrentMenuActivetttttt('/personal/myCollection?type=4') &&
                'active'
              "
                                @click="changePathName('收藏的店铺')"
                        >
                            <router-link to="/personal/myCollection?type=4">收藏的店铺</router-link>
                        </li>
                    </ul>
                    <p>
                       我的钱包
                    </p>
                    <ul style="border: none">
                        <li :class="
                checkCurrentMenuActive('/personal/accountCoin') && 'active'
              "
                                @click="changePathName('我的钱包')"
                        >
                            <router-link to="/personal/accountCoin">账户代币</router-link>
                        </li>
                        <li
                                :class="checkCurrentMenuActive('/personal/myCoupon') && 'active'"
                                @click="changePathName('我的钱包')"
                        >
                            <router-link to="/personal/myCoupon">优惠券</router-link>
                        </li>
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/myBankCard') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('我的钱包')"-->
<!--                        >-->
<!--                            <router-link to="/personal/myBankCard">银行卡</router-link>-->
<!--                        </li>-->
                    </ul>
<!--                    <p>-->
<!--                        <i/>账户中心-->
<!--                    </p>-->
<!--                    <ul>-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/accountInfo') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('账户中心')"-->
<!--                        >-->
<!--                            <router-link to="/personal/accountInfo">账户信息</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/familyInfo') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('账户中心')"-->
<!--                        >-->
<!--                            <router-link to="/personal/familyInfo">家庭圈信息</router-link>-->
<!--                        </li>-->
<!--                        <li-->
<!--                                :class="checkCurrentMenuActive('/personal/mySeek') && 'active'"-->
<!--                                @click="changePathName('账户中心')"-->
<!--                        >-->
<!--                            <router-link to="/personal/mySeek">我的咨询</router-link>-->
<!--                        </li>-->
<!--                        &lt;!&ndash; <li-->
<!--                          :class="-->
<!--                            checkCurrentMenuActive('/personal/myCollection') && 'active'-->
<!--                          "-->
<!--                          @click="changePathName('账户中心')"-->
<!--                        >-->
<!--                          <router-link to="/personal/myCollection">我的收藏</router-link>-->
<!--                        </li>&ndash;&gt;-->
<!--                        <li-->
<!--                                :class="-->
<!--                checkCurrentMenuActive('/personal/changePassword') && 'active'-->
<!--              "-->
<!--                                @click="changePathName('账户中心')"-->
<!--                        >-->
<!--                            <router-link to="/personal/changePassword">修改登陆密码</router-link>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                    <p>-->
<!--                        <i/>地址管理-->
<!--                    </p>-->
<!--                    <ul>-->
<!--                        <li-->
<!--                                :class=" checkCurrentMenuActive('/personal/shouHuoLocation') && 'active' "-->
<!--                                @click="changePathName('地址管理')"-->
<!--                        >-->
<!--                            <router-link to="/personal/shouHuoLocation">收货地址管理</router-link>-->
<!--                        </li>-->
<!--                        <li :class="checkCurrentMenuActive('/personal/fuWuLocation') && 'active'"-->
<!--                            @click="changePathName('地址管理')">-->
<!--                            <router-link to="/personal/fuWuLocation">服务信息管理</router-link>-->
<!--                        </li>-->
<!--                    </ul>-->
                </div>
                <div class="right"  :style="{width:_newTab?' 12.7rem':'14rem'}">
                    <router-view v-if="ifroutershgow"/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import Header from "@/components/Header.vue";
    import personalHeaderList from "@/components/personalHeader.vue";
    import Footer from "@/components/Footer.vue";
    import Nav from "@/components/Nav.vue";
    import MyBreadCrumb from "@/components/MyBreadCrumb.vue";

    @Component({
        components: {
            Header,
            personalHeaderList,
            Footer,
            Nav,
            MyBreadCrumb,
        },
    })
    export default class PersonalLayout extends Vue {
        refresh() {
            this.ifroutershgow = false;
            this.$nextTick().then((r) => (this.ifroutershgow = true));
        }

        get _newTab() {
            let hiddenArr = [
                "personal/reservationDetail",
                "personal/reservationListDetail",
                "personal/ReservationCommnet",
                "personal/classServiceOne",
                "personal/serviceDetail",
                "personal/AskBuyAfterService",
                "personal/askBuyAfterService",
                "personal/judgeService",
                "personal/classOpenServiceOne1",
                "personal/returnService1",
                "personal/returnService2",
                "personal/returnService3",
                "personal/diagnosisEvaluationCommnet",

                "personal/classOpenServiceOne",
                "personal/OrderDetail",
                "personal/serviceOrderDetail",
                "personal/goodsJudgeService",
                "personal/classServiceTow",
                "personal/AskBuyAfter",
                "personal/ReturnGoodsOne",
                "personal/ReturnGoodsTwo",
                "personal/ReturnGoodsThree",
                "personal/returnGoods1",
                "personal/returnGoods2",
                "personal/returnGoods3",
                "personal/returnGoods4",
                "personal/ReturnGoods1",
                "personal/ReturnGoods2",
                "personal/ReturnGoods3",
                "personal/ReturnGoods4",

            ];
            let m = hiddenArr.find((item) => location.href.includes(item));

            return m ? false : true;
        }

        jumphome(e) {
            e.preventDefault();
            let {href} = this.$router.resolve({
                path: "/home",
            });
            window.open(href, "_blank");
        }

        onSearchChange(e) {
            this.searchVal = e.target.value;
            sessionStorage.setItem("search", e.target.value);
        }

        onSearch(value) {
            const path = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1,
                window.location.href.length
            );
            if (path !== "servicePage") {
                this.$router.push("/servicePage");
            }
            if (this.$props.handleNavSearch) {
                this.$props.handleNavSearch(value);
            }
        }

        GRZXShowBool = false;
        DZGLShowBool = false;
        searchVal = "";
        currentPathName = "我的服务";
        firstPathName = "订单中心";
        Information = [];
        addressList = [];
        obj = {
            myHomePage: "我的首页",
            myService: "我的服务>服务订单",
            thirdPartyOrder: "我的服务>第三方服务订单",
            serviceOrderList: "我的服务>服务订购单",
            OpenService: "我的服务>服务开启列表",
            returnservice: "我的服务>申请/查询服务退款",
            cancelService: "我的服务>取消服务订单记录",
            fuwuxiaofei: "我的服务>服务消费明细",
            serveComment: "我的服务>服务评价",
            judgeService: "我的服务>服务订单评价",
            classServiceTow: "我的服务>服务详情",
            serviceDetail: "我的服务>服务订单详情",
            AskBuyAfterService: "我的服务>申请售后",
            returnService1: "我的服务>申请退款",
            //
            mygoods: "我的商品>商品订单",
            returngoods: "我的商品>申请/查询退换货",
            cancelGoods: "我的商品>取消商品订单记录",
            payDetail: "我的商品>商品消费明细",
            shopComment: "我的商品>商品评价",
            //
            myReservation: "我的预约>机构预约单",
            reservationList: "我的预约>诊疗预约单",
            reservationListTrashCan: "我的预约>诊疗预约单回收站",

            Cancelyuyve: "我的预约>取消预约单记录",
            yuyveComment: "我的预约>预约单评价",
            reservationTrashCan: "我的预约>预约单回收站",
            //
            shouHuoLocation: "地址管理>收货地址管理",
            fuWuLocation: "地址管理>服务信息管理",
            //
            accountInfo: "账户中心>账户信息",
            familyInfo: "账户中心>家庭圈信息",
            mySeek: "账户中心>我的咨询",
            changePassword: "账户中心>修改登陆密码",
            //
            accountCoin: "我的钱包>账户代币",
            myCoupon: "我的钱包>优惠券",
            myBankCard: "我的钱包>银行卡",
            //
            "myCollection?type=1": "我的收藏>收藏的服务",
            "myCollection?type=3": "我的收藏>收藏的服务商",
            "myCollection?type=2": "我的收藏>收藏的商品",
            "myCollection?type=4": "我的收藏>收藏的店铺",
            //
            "reservationDetail?": "我的预约>机构预约单",
            "reservationListDetail?": "我的预约>诊疗预约单",
        };

        objpath = {
            "/personal/reservationDetail": "/personal/myReservation",
            "/personal/reservationListDetail": "/personal/myReservation",
        };

        created() {
            const a = location.href.split("/").reverse()[0];
            const k = Object.keys(this.obj).find((ite) => a.includes(ite));

            this.firstPathName = this.obj[k].split(">")[0];
            this.currentPathName = this.obj[k].split(">")[1];

        }

        mounted() {
            const a = this.$route.path;
            const b = this.objpath[a];
            this.$refs.MyBreadCrumb.setSecondPath(b);
            this.Information = [
                {name: "账户信息", path: '/personal/accountInfo'},
                // {name: "家庭圈信息", path: '/personal/familyInfo'},
                // {name: "我的咨询", path: '/personal/mySeek'},
                {name: "用户评分", path: '/personal/userRatings'},
                {name: "账户安全", path: '/personal/changePassword'},
            ];
            this.addressList = [
                {name: "服务地址管理", path: '/personal/fuWuLocation'},
                {name: "收货地址管理", path: '/personal/shouHuoLocation'},
            ];
        }

        ifroutershgow = true;

        reloadifroutershgow() {
            this.ifroutershgow = false;
            this.$nextTick(() => (this.ifroutershgow = true));
        }

        changePathName(firstPathName) {
            this.currentPathName = this.$route.matched[
            this.$route.matched.length - 1
                ].name;
            this.firstPathName = firstPathName;
            this.reloadifroutershgow();
        }

        checkCurrentMenuActivetttttt(url) {
            return window.location.href.includes(url);
        }

        checkCurrentMenuActive(menuPath) {
            if (menuPath === "/personal/accountCoin") {
                switch (this.$route.path) {
                    case "/personal/accountCoin":
                        return true;
                    case "/personal/accountCoinHistory":
                        return true;
                    case "/personal/investCoin":
                        return true;
                    case "/personal/payCoin":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myHomePage") {
                switch (this.$route.path) {
                    case "/personal/myHomePage":
                        return true;
                }
            } else if (menuPath === "/personal/mygoods") {
                switch (this.$route.path) {
                    case "/personal/MyGoods":
                        return true;
                    case "/personal/trashcan":
                        return true;
                    case "/personal/judgeOrder":
                        return true;
                    case "/personal/orderDetail":
                        return true;
                    case "/personal/receiptApply":
                        return true;
                    case "/personal/receiptDetail":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myService") {
                switch (this.$route.path) {
                    case "/personal/myService":
                        return true;
                    case "/personal/serviceTrashcan":
                        return true;
                    case "/personal/serviceReceiptDetail":
                        return true;
                    case "/personal/classServiceTow":
                        return true;
                    case "/personal/classServiceOne":
                        return true;
                    case "/personal/judgeService":
                        return true;
                    case "/personal/serviceDetail":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/serviceOrderList") {
                switch (this.$route.path) {
                    case "/personal/serviceOrderList":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/thirdPartyOrder") {
                switch (this.$route.path) {
                    case "/personal/thirdPartyOrder":
                        return true;
                    default:
                        return false;
                }
            }  else if (menuPath === "/personal/OpenService") {
                switch (this.$route.path) {
                    case "/personal/OpenService":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myCollection") {
                switch (this.$route.path) {
                    case "/personal/myCollection":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myReservation") {
                switch (this.$route.path) {
                    case "/personal/myReservation":
                        return true;
                    case "/personal/reservationTrashCan":
                        return true;
                    case "/personal/reservationDetail":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/reservationList") {
                switch (this.$route.path) {
                    case "/personal/reservationList":
                        return true;
                    case "/personal/reservationListTrashCan":
                        return true;
                    case "/personal/reservationListDetail":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/mySeek") {
                switch (this.$route.path) {
                    case "/personal/mySeek":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/returnservice") {
                switch (this.$route.path) {
                    case "/personal/returnservice":
                        return true;
                    case "/personal/returnService1":
                        return true;
                    case "/personal/returnService2":
                        return true;
                    case "/personal/returnService3":
                        return true;
                    case "/personal/returnMoney1":
                        return true;
                    case "/personal/askBuyAfterService":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/returngoods") {
                switch (this.$route.path) {
                    case "/personal/returngoods":
                        return true;
                    case "/personal/askBuyAfter":
                        return true;
                    case "/personal/returnGoods1":
                        return true;
                    case "/personal/returnGoods2":
                        return true;
                    case "/personal/returnGoods3":
                        return true;
                    case "/personal/returnGoods4":
                        return true;

                    case "/personal/returnMoney2":
                        return true;
                    case "/personal/returnMoney3":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/cancelService") {
                switch (this.$route.path) {
                    case "/personal/cancelService":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/cancelGoods") {
                switch (this.$route.path) {
                    case "/personal/cancelGoods":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myComment") {
                switch (this.$route.path) {
                    case "/personal/myComment":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/accountInfo") {
                switch (this.$route.path) {
                    case "/personal/accountInfo":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/familyInfo") {
                switch (this.$route.path) {
                    case "/personal/familyInfo":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/changePassword") {
                switch (this.$route.path) {
                    case "/personal/changePassword":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/shouHuoLocation") {
                switch (this.$route.path) {
                    case "/personal/shouHuoLocation":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/fuWuLocation") {
                switch (this.$route.path) {
                    case "/personal/fuWuLocation":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/payDetail") {
                switch (this.$route.path) {
                    case "/personal/payDetail":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/fuwuxiaofei") {
                switch (this.$route.path) {
                    case "/personal/fuwuxiaofei":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myCoupon") {
                switch (this.$route.path) {
                    case "/personal/myCoupon":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/myBankCard") {
                switch (this.$route.path) {
                    case "/personal/myBankCard":
                        return true;
                    case "/personal/addBankCard":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/shopComment") {
                switch (this.$route.path) {
                    case "/personal/shopComment":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/yuyveComment") {
                switch (this.$route.path) {
                    case "/personal/yuyveComment":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/serveComment") {
                switch (this.$route.path) {
                    case "/personal/serveComment":
                        return true;
                    default:
                        return false;
                }
            } else if (menuPath === "/personal/Cancelyuyve") {
                switch (this.$route.path) {
                    case "/personal/Cancelyuyve":
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        }
    }
</script>

<style lang="less">
    .main {
        background-color: #f2f2f2;
        font-size: 0.18rem;
        padding-bottom: 0.4rem;
        margin: 0 auto;

        .my-breadcrumb {
            display: flex;
            align-items: center;
            // height: 0.73rem;
            height: 0.43rem;

            width: 14.66rem;
            margin: 0 auto;
            visibility: hidden;
        }

        .firstPage {
            width: 100%;
            height: 0.81rem;
            margin-top: -0.44rem;
            background: #FFFFFF;
            margin-bottom: 0.05rem;
            /*border: 1px solid red;*/
            opacity: 1;
            z-index: 99;
            position: relative;
            .home_menu {
                white-space: nowrap;
                height: 0.81rem;
                position: relative;
                width: 18.2rem;
                border: 1px solid #fff;
            }

            .home_logo {
                height: 0.61rem;
                width: 1.25rem;
                background-image: url("../../assets/images/homeImg/组 1472@2x.png");
                background-position: center center;
                background-size: 100%;
                background-repeat: no-repeat;
                cursor: pointer;
                margin-top: 0.05rem;
            }

            .home_search {
                width: 5rem;
                height: 0.81rem;
                position: absolute;
                left: 4.3rem;
                top: 50%;
                transform: translateY(-50%);
                display: flex;

                .home_left, .home_center, .home_right {
                    width: 1.4rem;
                    height: 100%;
                    text-align: center;
                    /*border: 1px solid red;*/
                }
                .active {
                    span {
                        color: #60c7c6 !important;
                    }
                }

                .home_left {
                    line-height: 0.81rem;

                    span {
                        font-size: 0.16rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #2A2E43;
                        opacity: 1;
                        cursor: pointer;

                    }

                    span:hover {
                        color: #70CECA;
                    }
                }


                .home_center, .home_right {
                    .account {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.16rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #2A2E43;
                        opacity: 1;
                        line-height: 0.81rem;
                        cursor: pointer;

                        .triangle {
                            width: 0;
                            height: 0;
                            opacity: 1;
                            margin-top: 0.03rem;
                            margin-left: 0.05rem;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-top: 7px solid #2A2E43;
                        }
                    }

                    .homeTheDropDownCard {
                        background-color: #fff;
                        position: relative;
                        z-index: 99;
                        width: 90%;
                        /*height: 1.95rem;*/
                        margin-left: 0.1rem;
                        box-shadow: 0 0 6px 1px #ddd;
                        margin-top: -0.15rem;
                        border-top: 5px solid #70CECA;
                        opacity: 1;
                        /*border: 1px solid red;*/

                        .homeGRZX {
                            list-style: none;
                            text-align: center;
                            background: #fff;
                        }

                        .homeGRZX > dd {
                            border-bottom: 0.5px solid rgba(229,229,229,1);
                            font-weight: bold;
                            color: #171717;
                            font-size: 0.14rem;
                            line-height: 0.47rem;
                            height: 0.47rem;
                            width: 70%;
                            margin: 0 auto;
                            padding-left: 0.05rem;
                            text-align: left;
                            /*border: 1px solid red;*/
                        }

                        .homeGRZX > dd:last-child {
                            border: none;
                        }

                        .homeGRZX > dd > a {
                            height: 0.4rem;
                            font-size: 0.14rem;
                            display: inline-block;
                            line-height: 0.45rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #454F63;
                        }

                        .homeGRZX > dd > a:hover {
                            color: #60C7C6;
                        }

                    }


                }

                .home_center:hover {
                    .account {
                        color: #5FC6C5;

                        .triangle {
                            width: 0;
                            height: 0;
                            opacity: 1;
                            margin-top: 0.03rem;
                            margin-left: 0.05rem;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-top: 7px solid #5FC6C5;
                        }
                    }

                }

                .home_right:hover {
                    .account {
                        color: #5FC6C5;

                        .triangle {
                            width: 0;
                            height: 0;
                            opacity: 1;
                            margin-top: 0.03rem;
                            margin-left: 0.05rem;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-top: 7px solid #5FC6C5;
                        }
                    }

                }

            }

            .home_seek {
                width: 4rem;
                height: 100%;
                position: absolute;
                left: 9.3rem;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                /*border: 1px solid red;*/


                .home_input {
                    /*width: 4rem;*/
                    display: flex;
                    align-items: center;
                    .ant-input {
                        width: 3.3rem;
                        font-size: 0.14rem;
                        height: 0.44rem;
                        /*border: 1px solid red;*/

                    }
                    .ant-input{
                        &::placeholder {
                            color: #78849E;
                            font-size: 12px;
                            opacity: 1;
                        }
                    }

                    button.ant-btn.ant-btn-primary.ant-input-search-button {
                        width: 0.77rem;
                        height: 0.44rem;
                        background-color: #60c7c6;
                        border-radius: 0 0.5rem 0.5rem 0;
                        border-color: #60c7c6;
                        font-size: 0.2rem;
                    }

                    input.ant-input:focus {
                        border-color: #d9d9d9;
                        border-radius: 0.5rem 0 0 0.5rem;
                        -webkit-box-shadow: 0 0 0 0;
                        box-shadow: 0 0 0 0;
                    }

                    input.ant-input:hover {
                        border-color: #d9d9d9;
                    }

                    input.ant-input {
                        border-radius: 0.5rem 0 0 0.5rem;
                    }
                }


            }
        }

        p {
            color: #171717;
            font-size: 0.18rem;
            margin-bottom: 0.08rem;
        }

        .homeCenter {
            display: flex;
            justify-content: center;
            width: 18.2rem;
            padding-left: 0.9rem;
            z-index: 1;
            /*border: 1px solid red;*/

            .left {
                width: 1.99rem;
                background-color: #fff;
                margin-right: 0.2rem;
                padding: 0.3rem 0.16rem;
                /*border: 1px solid black;*/
                min-height: 12.5rem;
                max-height: 12.5rem;

                .mine {
                    width: 2.1rem;
                    height: 0.55rem;
                    border: 1px solid #dcdcdc;
                    margin-left: -0.21rem;
                    margin-top: -0.3rem;
                    margin-bottom: 0.25rem;
                    border-bottom: none;
                    border-top-right-radius: 0.05rem;
                    border-top-left-radius: 0.05rem;
                    background-color: #fafafa;

                    .homePage {
                        height: 0.48rem;
                        cursor: pointer;

                        a {
                            color: #737373;
                            font-size: 0.17rem;
                            margin: 0 auto;
                            text-align: center;
                            height: 100%;
                            line-height: 0.58rem;
                            padding-left: 0.2rem;
                            background: url("../.././assets/images/newicon/icon_menu.png") 0.01rem 0.2rem no-repeat #fafafa;
                        }

                        a:focus {
                            text-decoration: none;
                        }

                        a:hover {
                            color: #60c7c6;
                        }
                    }

                    .active {
                        a {
                            color: #60c7c6;
                        }
                    }

                    .mine2 {
                        height: 0.2rem;
                        margin-top: -0.03rem;
                        background: url("../.././assets/images/newicon/bg.png") -2px 0 no-repeat;
                        background-size: 2.125rem;
                    }
                }


                p {
                    display: flex;
                    align-items: center;
                    font-weight: Bold;
                    color: #454F63;
                    font-size: 16px;
                    padding-left: 15px;
                    opacity: 1;
                    i {
                        display: inline-block;
                        width: 5px;
                        height: 17px;
                        background-color: #60c7c6;
                        margin-right: 10px;
                    }


                }

                ul {
                    list-style: none;
                    margin: 0;
                    margin-bottom: 0.2rem;
                    padding: 0;
                    padding-left: 15px;
                    padding-bottom: 0.15rem;
                    border-bottom: 1px solid #ebeef3;

                    li {
                        padding: 0.09rem 0;
                        /*border: 1px solid red;*/

                        a {
                            color: #454F63;
                            font-size: 0.14rem;
                        }

                        a:focus {
                            text-decoration: none;
                        }
                    }

                    li:hover {
                        a {
                            color: #60c7c6;
                        }
                    }

                    .active {
                        a {
                            color: #60c7c6;
                        }
                    }
                }
            }

            .right {
                border-radius: 0.12rem;
                background-color: #fff;
                /*border: 1px solid red;*/
            }
        }

    }
</style>
