























    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Breadcrumb} from "ant-design-vue";

    Vue.use(Breadcrumb);

@Component({
  // props: {
  //   firstPathName: String,
  //   pathName: String
  // }
})
export default class MyBreadCrumb extends Vue {
  // firstPath: string = "21";
  secondPath: string = "";

  PathName2path = {
    我的首页: "/personal/myHomePage",
    我的服务: "/personal/myService",
    我的商品: "/personal/mygoods",
    我的预约: "/personal/myReservation",
    我的收藏: "/personal/myCollection?type=1",
    我的钱包: "/personal/accountCoin",
    账户中心: "/personal/accountInfo",
    地址管理: "/personal/shouHuoLocation"
  };

  @Prop() private firstPathName: any;
  @Prop() private pathName: any;

  get firstPath() {
    return this.PathName2path[this.firstPathName];
  }

  jump() {
    this.$router.push(this.firstPath);
    this.$emit("refresh");
  }
  jump2() {
    this.$router.push("/personal/myService");
    this.$emit("refresh");
  }
  setSecondPath(path) {
    this.secondPath = path;
  }
  ttesioh() {
    this.$router.push(this.secondPath);
  }
}
